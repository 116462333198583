
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.tech-name {
  display: flex;
  align-items: center;
}
.tech-name,
::v-deep .setting-item {
  width: 100%;
  padding: 10px;
  background-color: $color-black-op-05;
  border-radius: $base-border-radius;
  margin-top: 10px;

  &:first-child {
     margin: 0;
   }

  .esmp-select-head {
    background-color: $color-black-op-05;
  }
}
